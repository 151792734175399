import React, { useContext } from 'react'
import ReactDOM from 'react-dom'
import { AuthContext, AuthProvider, TAuthConfig } from "react-oauth2-code-pkce"

const authConfig: TAuthConfig = {
  clientId: 'motivu',
  authorizationEndpoint: 'https://auth.motivhealth.com/connect/authorize',
  tokenEndpoint: 'https://auth.motivhealth.com/connect/token',
  redirectUri: 'https://testouth.showmyproject.net/',
  scope: 'member openid offline_access',
  decodeToken : false
}

function LoginInfo() {
  const { 
    tokenData, 
    token, 
    login, 
    logOut, 
    error, 
    loginInProgress 
  } = useContext(AuthContext)
  
  // Stops the webpage from flickering while logging in
  if (loginInProgress) return null  
  
  if (error) {
    return (
      <>
        <div style={{ color: 'red' }}>
          An error occurred during authentication: {error}
        </div>
        <button onClick={() => logOut()}>Logout</button>
      </>
    )
  }
  if (!token) {
    return (
      <>
        <div style={{ backgroundColor: 'red' }}>
          You are not logged in
        </div>
        <button onClick={() => login()}>Login</button>
      </>
    )
  } else {

    return (
      <>
        <div>
          <h4>Access Token (JWT)</h4>
          <pre>{token}</pre>
        </div>
        <div>
          <h4>Login Information from Access Token (Base64 decoded JWT)</h4>
          <pre>{JSON.stringify(tokenData, null, 2)}</pre>
        </div>

        <button onClick={() => logOut()}>Logout</button>
      </> 
    )

  }
    
}

ReactDOM.render(
    <div>
      <AuthProvider authConfig={authConfig}>
        <LoginInfo/>
      </AuthProvider>
    </div>, document.getElementById('root'),
)